
.jss9 {
    color: #fff;
}
.css-jr57cz-MuiToolbar-root{
    background-color: #94CDD6;
}
body{
    color:#0D444D;
}
pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid  #94CDD6;
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
}
.ml30{
    margin-left: 30px;
}
.nv{
    color:#0D444D;
}
code{
    font-weight: 600;
    display: block;
}
.notice {
    color: #EF5350;
}

.paramsTable tbody tr td:first-child {
    text-align: right;
    width: 30%;
}
.paramsTable td {
    padding: 10px;
}

.paramsTable th, .paramsTable td {
    text-align: left;
    vertical-align: top;
    line-height: 1.6;
}

.information-container,
.scheme-container
{
    display:none !important; 
}

.opblock-tag{
    font-size: 1rem !important;
}